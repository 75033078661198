
    /***** Navbar CSS *****/

    .navbar-default {
    background-color: transparent;
    border:none;
    font-family: 'Lato', sans-serif;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
    padding: 20px 0 10px 10px;
    }

    .navbar-default.affix {
    background-color: #F1C71A;
    border-color: rgba(241, 198, 26, 0.2);
    border-bottom: 1px solid rgba(241, 198, 26, 0.2);
    opacity: 1;
    padding: 10px 0 10px 10px;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.8);
    }
   
    .navbar-default.affix .navbar-brand{
        background: url('../images/logo_white.png') no-repeat !important;
    }
   

    .navbar-default  .navbar-brand {
    color: #f1c40f;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    background: url('../images/logo_987ysPf.png') no-repeat;
    min-width: 150px;
    margin-top: 5px;
    }

    .navbar-default .navbar-header .navbar-brand:hover,
    .navbar-default .navbar-header .navbar-brand:focus {
    color: #eb3812;
    }

    .navbar-default .navbar-header .navbar-toggle {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    background: #F1C71A;
    border-color: #F1C71A;
    }

    .navbar-default.affix .navbar-header .navbar-toggle {
    border-color: #fff;
    }

    .navbar-default .navbar-right{
    margin-top: 2px;
    }

    .navbar-default .nav > li > a{
    padding-right: 10px;
    padding-left: 10px;
    }

    .navbar-default .nav > li > a,
    .navbar-default .nav > li > a:focus {
    font-weight: 500;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    }

    .navbar-default.affix .nav > li > a,
    .navbar-default.affix .nav > li > a:focus{
    color:#fff;
    }

    .navbar-default.affix .nav > li > a:hover,
    .navbar-default.affix .nav > li > a:focus:hover {
    color: #FCF0C7;
    }

    .navbar-default.affix .nav > li.active > a,
    .navbar-default.affix .nav > li.active > a:focus {
    color: #FCF0C7 !important;
    background-color: transparent;
    }

    .navbar-default .nav > li > a:hover,
    .navbar-default .nav > li > a:focus:hover {
    color: #f1c40f;
    }

    .navbar-default .nav > li.active > a,
    .navbar-default .nav > li.active > a:focus {
    color: #f1c40f !important;
    background-color: transparent;
    }

    .navbar-default .nav > li.active > a:hover,
    .navbar-default .nav > li.active > a:focus:hover {
    background-color: transparent;
    }

    .navbar-default .navbar-toggle{
    border-color: #fff;
    }

    .navbar-default .navbar-toggle:focus, 
    .navbar-default .navbar-toggle:hover {
    background-color: #F1C71A;
    }

    .select2-search__field {
        background: transparent;
        color: black;
    }

    .select2-container--classic .select2-selection--single:focus, .select2-container--default .select2-selection--single:focus {
        border-color: #F1C61A !important;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1) !important;
    }

    .select2-container--classic.select2-container--open .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--single {
        border-color: #F1C61A !important;
        outline: 0;
    }

    .select2-container .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: 50px;
        font-weight: 700;
        user-select: none;
        -webkit-user-select: none;
    }
.btn-flotante {
	text-transform: uppercase; 
	font-weight: bold; 
	border-radius: 30px;
	background-color: #F1C71A;
	padding: 10px 20px; 
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante a:focus, a:hover{
    color:'white'
}
a:hover{
    color: 'white';
    text-decoration: none;
}
.btn-flotante:hover {
	background-color: #E4B300; 
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
}
/* cuando se haga scroll en la pagina*/