
.PageNumberContainer{
  width: 100%;
  padding: 10px;
  margin-left: 20px;
  display: grid;
  justify-content: left;
  align-items: left;
  font-size: 1.2em;
}

.NumberWrap{
  max-width: 100%;
  display: flex;
  overflow: auto;
}

.PageNumber{
  height: 100%;
  font-size: 0.75em;
  margin: 0;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
  padding: 14px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.PageNumber:hover{
  color: #f5f5f5;
}


.ArrowContainer {
  margin-right: 12px;
  margin-left: 12px;
  width: 60px;
  
 
}
.ArrowContainer div span{
  margin-left: 6px;
  margin-right: 6px;
  color: #696773;
  cursor: pointer;
  transition: 0.3s;
  font-size: 0.75em;
  transition: 0.3s ease all;
  
}
.ArrowContainer:hover{
  transition: 0.3s ease all;
  color: #819595;
}

.ArrowContainer span:hover{
  color: '#819595';
  transition: 0.3s;
}